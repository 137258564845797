import Tooltip from "@mui/material/Tooltip";
import React from "react";
import useUserTracking from "../customHook/userTracking";

const WebsiteBuilderSteps = ({ callback, themeSelected, themeDetails }) => {
  const steps = [
    {
      stepNumber: 1,
      title: "Register Your Domain",
      titleTwo: "Your Domain",
    },
    {
      stepNumber: 2,
      title: "Select Your Theme",
      titleTwo: "Selected Theme",
    },
    {
      stepNumber: 3,
      title: "Home Page Customization",
    },
    {
      stepNumber: 4,
      title: "Website Customization",
    },
    {
      stepNumber: 5,
      title: "Make Website Live",
    },
  ];

  // trackUser Analytics
  const { trackUser } = useUserTracking();

  const onProceed = (step) => {
    callback(step.stepNumber);

    // user tracking data
    const trackingUserData = {
      page: "Website Builder Steps",
      page_details: step,
      method: "click",
    };
    // Call the trackUser function from the hook
    trackUser(trackingUserData);
  };

  return (
    <div className="w-full border bg-white shadow-lg rounded-lg flex flex-col items-center  px-4 py-4 overflow-auto h-full gap-y-7">
      {steps.map((step) => (
        <div
          key={step.stepNumber}
          className={`card w-full shadow-md transform transition-all duration-500 ease-in-out p-4 gap-y-4 rounded-lg  border`}
        >
          <div className="flex flex-col sm:flex-row items-center justify-between w-full">
            <div className="flex-1 text-xl  text-blue-600 mb-2 sm:mb-0">
              Step {step.stepNumber}
            </div>
            <div className="flex-1  text-lg mb-2 sm:mb-0  bg-clip-text text-blue-600 whitespace-nowrap">
              {step.title}
            </div>
            <div className="flex-1"></div>
            <Tooltip
              title={
                step.stepNumber === 5 && !themeSelected
                  ? "Please select a theme to make it live"
                  : ""
              }
              placement="top"
              arrow
            >
              <button
                onClick={() => onProceed(step)}
                className={`proceed-btn flex-1 py-2 px-4  text-white rounded-lg shadow-md transform transition-all duration-300 ease-in-out hover:bg-blue-600 hover:scale-105 
              ${
                themeDetails && (step.stepNumber == 1 || step.stepNumber == 2)
                  ? "bg-green-600"
                  : "bg-blue-600"
              }
              ${
                step.stepNumber === 5 && !themeSelected
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }
              `}
                disabled={step.stepNumber === 5 && !themeSelected}
              >
                {themeDetails && (step.stepNumber == 1 || step.stepNumber == 2)
                  ? "Done"
                  : "Proceed"}
              </button>
            </Tooltip>
          </div>
        </div>
      ))}
    </div>
  );
};

export default WebsiteBuilderSteps;
