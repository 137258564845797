import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useToken } from "../Context/TokenContext";
import { BASE_URL } from "../config";

const REACT_APP_BASE_URL = BASE_URL;
const useUserTracking = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const [ipDetails, setIpDetails] = useState({});

  const { jboId, token } = useToken();

  // Check if IP details are stored in localStorage
  useEffect(() => {
    const storedIpDetails = localStorage.getItem("ipDetails");
    if (storedIpDetails) {
      setIpDetails(JSON.parse(storedIpDetails));
    } else {
      fetchIpDetails();
    }
    const ipRefreshInterval = setInterval(() => {
      fetchIpDetails();
    }, 600000);

    return () => clearInterval(ipRefreshInterval);
  }, []);

  const fetchIpDetails = useCallback(async () => {
    let ipDetails = {};

    try {
      // Attempt to fetch IP details using the primary service
      const ipResponse = await axios.get("https://api.ipify.org?format=json");
      const ipDetailsResponse = await axios.get(
        `https://ipapi.co/${ipResponse.data.ip}/json/`
      );
      ipDetails = ipDetailsResponse.data;
    } catch (primaryError) {
      console.warn(
        "Failed to fetch IP details from ipapi.co. Trying fallback..."
      );

      try {
        // Attempt to fetch IP details using the fallback service
        const fallbackResponse = await axios.get("https://ipwhois.app/json/");
        ipDetails = fallbackResponse.data;
      } catch (fallbackError) {
        console.warn(
          "Failed to fetch IP details from fallback service. Proceeding without IP data."
        );
      }
    }

    if (ipDetails) {
      setIpDetails(ipDetails);
      localStorage.setItem("ipDetails", JSON.stringify(ipDetails));
    } else {
      setError("Failed to fetch IP details");
    }
  }, []);

  const trackUser = async ({ page, page_details = "", method }) => {
    setLoading(true);
    setError(null);
    setResponse(null);

    const utcTime = new Date().toISOString();

    const currentTime = utcTime;

    try {
      const url = "https://your-api-url.com";
      const payload = {
        jboId,
        token,
        ipDetails,
        page,
        page_details,
        method,
        currentTime,
      };

      const trackerResponse = await axios.post(
        `${REACT_APP_BASE_URL}/user-activity-log`,
        payload
      );
      setResponse(trackerResponse.data);
    } catch (err) {
      setError(err.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  function compareJSON(initialData, modifiedData, excludedKeys = []) {
    const differences = [];

    function compareObjects(obj1, obj2, path = "") {
      // Loop through all keys of both objects
      for (let key in obj1) {
        if (
          obj1.hasOwnProperty(key) &&
          !excludedKeys.includes(path ? `${path}.${key}` : key)
        ) {
          const newPath = path ? `${path}.${key}` : key;

          if (!obj2.hasOwnProperty(key)) {
            // Key exists in obj1 but not in obj2 (deleted)
            differences.push({
              path: newPath,
              action: "deleted",
              value: obj1[key],
            });
          } else {
            const value1 = obj1[key];
            const value2 = obj2[key];

            // If the value is an object, recurse into it
            if (
              typeof value1 === "object" &&
              value1 !== null &&
              typeof value2 === "object" &&
              value2 !== null
            ) {
              compareObjects(value1, value2, newPath);
            } else {
              // If values are different, record the difference
              if (value1 !== value2) {
                differences.push({
                  path: newPath,
                  action: "modified",
                  oldValue: value1,
                  newValue: value2,
                });
              }
            }
          }
        }
      }

      // Check for keys in obj2 that don't exist in obj1 (added keys)
      for (let key in obj2) {
        if (
          obj2.hasOwnProperty(key) &&
          !obj1.hasOwnProperty(key) &&
          !excludedKeys.includes(path ? `${path}.${key}` : key)
        ) {
          const newPath = path ? `${path}.${key}` : key;
          differences.push({
            path: newPath,
            action: "added",
            value: obj2[key],
          });
        }
      }
    }

    // Handle array and object data types
    function compareArrays(arr1, arr2, path) {
      arr1.forEach((item, index) => {
        if (!excludedKeys.includes(`${path}[${index}]`)) {
          const newPath = `${path}[${index}]`;
          if (arr2[index]) {
            compareObjects(item, arr2[index], newPath);
          } else {
            differences.push({
              path: newPath,
              action: "deleted",
              value: item,
            });
          }
        }
      });

      // Handle additional items in arr2 (added)
      arr2.forEach((item, index) => {
        if (!excludedKeys.includes(`${path}[${index}]`)) {
          const newPath = `${path}[${index}]`;
          if (!arr1[index]) {
            differences.push({
              path: newPath,
              action: "added",
              value: item,
            });
          }
        }
      });
    }

    // Compare root-level objects
    if (Array.isArray(initialData)) {
      compareArrays(initialData, modifiedData, "");
    } else {
      compareObjects(initialData, modifiedData);
    }

    // If no differences were found, return 'nochange'
    return differences.length === 0 ? "nochange" : differences;
  }

  return { loading, error, response, trackUser, compareJSON };
};

export default useUserTracking;
// import { useState, useEffect, useCallback } from "react";
// import axios from "axios";
// import { useToken } from "../Context/TokenContext";

// const useUserTracking = () => {
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [response, setResponse] = useState(null);
//   const [ipDetails, setIpDetails] = useState(null);

//   const { jboId, token } = useToken();

//   // Check if IP details are stored in localStorage
//   useEffect(() => {
//     const storedIpDetails = localStorage.getItem("ipDetails");
//     if (storedIpDetails) {
//       setIpDetails(JSON.parse(storedIpDetails));
//     } else {
//       fetchIpDetails();
//     }
//     const ipRefreshInterval = setInterval(() => {
//       fetchIpDetails();
//     }, 600000);

//     return () => clearInterval(ipRefreshInterval);
//   }, []);

//   const fetchIpDetails = useCallback(async () => {
//     let ipDetails = null;

//     try {
//       // Attempt to fetch IP details using the primary service
//       const ipResponse = await axios.get("https://api.ipify.org?format=json");
//       const ipDetailsResponse = await axios.get(
//         `https://ipapi.co/${ipResponse.data.ip}/json/`
//       );
//       ipDetails = ipDetailsResponse.data;
//     } catch (primaryError) {
//       console.warn(
//         "Failed to fetch IP details from ipapi.co. Trying fallback..."
//       );

//       try {
//         // Attempt to fetch IP details using the fallback service
//         const fallbackResponse = await axios.get("https://ipwhois.app/json/");
//         ipDetails = fallbackResponse.data;
//       } catch (fallbackError) {
//         console.warn(
//           "Failed to fetch IP details from fallback service. Proceeding without IP data."
//         );
//       }
//     }

//     if (ipDetails) {
//       setIpDetails(ipDetails);
//       localStorage.setItem("ipDetails", JSON.stringify(ipDetails));
//     } else {
//       setError("Failed to fetch IP details");
//     }
//   }, []);

//   const trackUser = async ({ page, page_details = "", method }) => {
//     setLoading(true);
//     setError(null);
//     setResponse(null);

//     const utcTime = new Date().toISOString();

//     const currentTime = utcTime;

//     try {
//       const url = "https://your-api-url.com";
//       const payload = {
//         jboId,
//         token,
//         ipDetails,
//         page,
//         page_details,
//         method,
//         currentTime,
//       };
//       console.log(payload);

//       //   const res = await axios.post(url, payload);
//       //   setResponse(res.data);
//     } catch (err) {
//       setError(err.message || "Something went wrong");
//     } finally {
//       setLoading(false);
//     }
//   };

//   function compareJSON(initialData, modifiedData, excludedKeys = []) {
//     const differences = [];

//     function compareObjects(obj1, obj2, path = "") {
//       // Loop through all keys of both objects
//       for (let key in obj1) {
//         if (obj1.hasOwnProperty(key) && !excludedKeys.includes(key)) {
//           // Skip if key is in excludedKeys
//           const newPath = path ? `${path}.${key}` : key;

//           if (!obj2.hasOwnProperty(key)) {
//             // Only push to 'deleted' if the key exists in initial data but not in modified data
//             differences.push({
//               path: newPath,
//               action: "deleted",
//               value: obj1[key],
//             });
//           } else {
//             const value1 = obj1[key];
//             const value2 = obj2[key];

//             // If the value is an object, recurse into it
//             if (
//               typeof value1 === "object" &&
//               value1 !== null &&
//               typeof value2 === "object" &&
//               value2 !== null
//             ) {
//               compareObjects(value1, value2, newPath);
//             } else {
//               // If values are different, record the difference
//               if (value1 !== value2) {
//                 differences.push({
//                   path: newPath,
//                   action: "modified",
//                   oldValue: value1,
//                   newValue: value2,
//                 });
//               }
//             }
//           }
//         }
//       }

//       // Check if there are any keys in obj2 that don't exist in obj1 (excluding keys in excludedKeys)
//       for (let key in obj2) {
//         if (
//           obj2.hasOwnProperty(key) &&
//           !obj1.hasOwnProperty(key) &&
//           !excludedKeys.includes(key)
//         ) {
//           const newPath = path ? `${path}.${key}` : key;
//           differences.push({
//             path: newPath,
//             action: "added",
//             value: obj2[key],
//           });
//         }
//       }
//     }

//     compareObjects(initialData, modifiedData);

//     // If no differences were found, return 'nochange'
//     if (differences.length === 0) {
//       return "nochange";
//     }

//     return differences;
//   }

//   return { loading, error, response, trackUser, compareJSON };
// };

// export default useUserTracking;
