import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../config";
import {
  FaRedo,
  FaCheckCircle,
  FaExclamationCircle,
  FaRegClock,
  FaCloudUploadAlt,
} from "react-icons/fa";
import Swal from "sweetalert2"; // Import SweetAlert2
import useUserTracking from "../../customHook/userTracking";

const ExistingDomain = () => {
  const [domainUrl, setDomainUrl] = useState("");
  const [domainData, setDomainData] = useState(null);
  const [domainStatus, setDomainStatus] = useState(null);
  const [domainType, setDomainType] = useState(null);
  const [lastUpdated, setLastUpdated] = useState("");
  const [loading, setLoading] = useState(true); // Introduce loading state
  const [domainLoading, setDomainLoading] = useState(false); // Initialize as false

  // trackUser Analytics
  const { trackUser } = useUserTracking();

  const fetchDomainData = async () => {
    if (!localStorage.getItem("domainTracker")) {
      localStorage.setItem("domainTracker", "off");
    }

    try {
      const response = await axios.get(`${BASE_URL}/domain/domain_status`);
      setDomainData(response.data);
      setLoading(false);
      setDomainStatus(response.data.domain_status);
      setDomainType(response.data.domain_type);
      const domainStatus =
        response.data.domain_status || localStorage.getItem("domainTracker");
      localStorage.setItem("domainTracker", domainStatus);

      // user tracking data
      const trackingUserData = {
        page: "Domain Management",
        page_details: { details: "Domain Details" },
        method: "click",
      };
      // Call the trackUser function from the hook
      trackUser(trackingUserData);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching domain data:", error);
    }
  };

  const handleRefreshClick = async () => {
    await fetchDomainData(); // Call the function to fetch domain data

    // Update and store the "Last Updated" time only if the domain status is "pending"
    if (domainStatus === "pending") {
      const currentTime = new Date().toLocaleTimeString("en-IN", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
        timeZone: "Asia/Kolkata",
      });
      const currentDate = new Date().toLocaleDateString("en-IN", {
        timeZone: "Asia/Kolkata",
      });
      const currentDateTime = `${currentDate} ${currentTime}`;

      setLastUpdated(currentDateTime);
      localStorage.setItem("lastUpdated", currentDateTime);

      // user tracking data
      const trackingUserData = {
        page: "Domain Management",
        page_details: { details: "Refresh domain status" },
        method: "click",
      };
      // Call the trackUser function from the hook
      trackUser(trackingUserData);
    }
  };

  useEffect(() => {
    fetchDomainData();
    if (domainStatus === "pending") {
      const storedTime = localStorage.getItem("lastUpdated");
      if (storedTime) {
        setLastUpdated(storedTime);
      }
    }
  }, [domainStatus]); // Add domainStatus as a dependency to re-run the effect when it changes

  const domainMessage = (status) => {
    // if (domainType === 0 && status === 'pending') {
    //   return (
    //     <span>
    //       <FaCheckCircle className="inline mr-2" />
    //       Thanks, Your Domain Purchase was Successful! Your domain order has been successfully placed! We will set up the nameservers to connect the domain to our application. The name servers will be updated in the next 24 hours. You will receive an email confirmation once the Domain Name Server (DNS) propagation is complete.
    //     </span>
    //   );
    // }
    switch (status) {
      case "pending":
        return (
          <span>
            <FaExclamationCircle className="inline mr-2" />
            Please update the following name server in your domain name DNS. DNS
            propagation will take 24-72 hrs. You will get an email once the
            domain is ready to use.
          </span>
        );
      case "active":
        return (
          <span>
            <FaCheckCircle className="inline mr-2" />
            Your domain is ready! Go and customize your website builder.
          </span>
        );
      default:
        return "";
    }
  };

  const handleSubmit = async (e) => {
    setDomainLoading(true); // Start loading
    e.preventDefault();
    try {
      await axios.post(`${BASE_URL}/domain/add_exist_domain`, {
        domain_name: domainUrl,
      });
      setDomainLoading(false);

      // user tracking data
      const trackingUserData = {
        page: "Domain Management",
        page_details: { details: "Add existing domain" },
        method: "click",
      };
      // Call the trackUser function from the hook
      trackUser(trackingUserData);
      fetchDomainData();
    } catch (error) {
      setDomainLoading(false);
      Swal.fire({
        icon: "error", // Use the "error" icon
        title: "Oops...", // Title of the alert
        text: "Something went wrong!", // Main text
      });
      console.error("There was an error!", error);
    }
  };

  if (loading) {
    return (
      <div className="p-4 lg:p-8 bg-white border shadow-md rounded-lg font-poppins animate-pulse h-full">
        <div className="flex justify-between items-center">
          <div className="shimmer w-3/4 h-6 mb-4"></div>
          {/* <div className="shimmer w-1/4 h-8"></div> */}
        </div>
        <div className="shimmer-bg w-full h-6 mb-4"></div>
        <div className="flex flex-col lg:flex-row items-center justify-between gap-8">
          <div className="lg:w-1/2">
            <div className="shimmer w-full h-48 rounded-lg"></div>
          </div>
          <div className="lg:w-1/2 space-y-4">
            <div className="shimmer w-3/4 h-8"></div>
            <div className="shimmer w-full h-4 mb-2"></div>
            <div className="shimmer w-1/2 h-4 mb-2"></div>
            <div className="shimmer w-2/3 h-4 mb-2"></div>
            <div className="shimme w-1/2 h-4"></div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="p-4 lg:p-8 border shadow-md rounded-lg h-full font-poppins bg-white">
      <div className="flex justify-between items-center">
        <span className="text-lg lg:text-xl font-semibold text-blue-600 flex items-center">
          {domainType !== 0 && domainStatus !== "pending"
            ? domainMessage(domainStatus)
            : " Thanks, Your Domain Purchase was Successful! Your domain order has been successfully placed! We will set up the nameservers to connect the domain to our application. The name servers will be updated in the next 24 hours. You will receive an email confirmation once the Domain Name Server (DNS) propagation is complete."}
        </span>
        {domainStatus === "pending" && (
          <button
            onClick={handleRefreshClick}
            className="flex items-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-200 ease-in-out transform hover:-translate-y-1"
          >
            <FaRedo className="mr-2" /> Refresh
          </button>
        )}
      </div>
      {domainStatus === "pending" && lastUpdated && (
        <p className="text-md text-gray-600 mb-4">
          <FaRegClock className="inline mr-2" />
          Last Updated: {lastUpdated}
        </p>
      )}

      <div className="flex flex-col lg:flex-row items-center justify-between gap-8">
        <div className="lg:w-1/2 flex justify-center">
          <img
            src="https://img.freepik.com/premium-vector/modern-design-illustration-domains-name_362714-1026.jpg"
            alt="Domain Setup"
            className="rounded-lg max-w-full h-80"
          />
        </div>
        <div className="lg:w-1/2 space-y-6">
          {domainData ? (
            <div className="space-y-4 bg-white font-poppins p-6 border rounded-lg shadow-md">
              <p className="text-2xl font-bold text-blue-800">
                Domain: {domainData.domain_name}
              </p>

              {domainData.renew_domain_date && (
                <p className="text-md text-gray-700">
                  Renew Domain Date:{" "}
                  {new Date(domainData.renew_domain_date)
                    .toLocaleDateString("en-IN", {
                      day: "2-digit", // Use two digits for the day
                      month: "2-digit", // Use two digits for the month
                      year: "numeric", // Use the full numeric value of the year
                    })
                    .split("/")
                    .join("-")}
                </p>
              )}

              {domainData.renew_ssl_date && (
                <p className="text-md text-gray-700">
                  Renew SSL Date: {domainData.renew_ssl_date}
                </p>
              )}

              {domainData.name_servers !== null &&
                domainData.domain_type === 1 &&
                domainData.domain_status !== "active" && (
                  <>
                    <h4 className="text-lg font-semibold text-gray-800 flex items-center">
                      <FaCheckCircle className="mr-2 text-green-500" />
                      Name Servers:
                    </h4>
                    <div className="bg-gray-100 p-4 rounded-lg">
                      <ul className="list-disc pl-5 space-y-1">
                        {domainData.name_servers?.map((server, index) => (
                          <li key={index} className="text-blue-600">
                            {server}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </>
                )}
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="mt-4">
              <div className="flex items-center border-b border-blue-500 py-2">
                <input
                  className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                  type="text"
                  placeholder="Enter your domain URL"
                  aria-label="Domain URL"
                  value={domainUrl}
                  onChange={(e) => setDomainUrl(e.target.value)}
                />
                <button
                  className="flex items-center bg-blue-500 hover:bg-blue-700 text-white text-sm py-2 px-4 rounded transition duration-200 ease-in-out transform hover:scale-105"
                  type="submit"
                >
                  <FaCloudUploadAlt className="mr-2" /> Submit
                </button>
              </div>
              {domainLoading ? (
                <div className="mt-4">
                  {/* Shimmer effect for loading */}
                  <div className="space-y-2">
                    <div className="h-6 w-48 bg-gray-100 rounded shimmer mt-3"></div>
                    <div className="h-4 bg-gray-100 rounded shimmer"></div>
                    <div className="h-4 bg-gray-100 rounded shimmer"></div>
                  </div>
                </div>
              ) : (
                false
              )}
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExistingDomain;
