import React, { useState, useEffect } from "react";
import ReactSwitch from "react-switch";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import useAxios from "../../Axios/useAxios";
import useTokenFromURL from "../../Context/useTokenFromURL";
import Swal from "sweetalert2";
import { BASE_URL } from "../../config";
import { MdAdd, MdDelete } from "react-icons/md";
import useUserTracking from "../../customHook/userTracking";
const REACT_APP_BASE_URL = BASE_URL;

/**
 * Products Component
 *
 * This component allows users to manage settings and preferences
 * related to products on the website. Users can toggle the view of products,
 * determine how many products to display, and fetch/update product-related data.
 *
 * @param {Function} onToggle - Callback to manage accordion toggling.
 * @param {string} activeAccordion - Represents the currently active accordion name.
 * @param {Function} IsSavedInputs - Callback to notify about unsaved inputs.
 */

const SEO = ({ onToggle, activeAccordion, IsSavedInputs }) => {
  const [seoSettings, setSeoSettings] = useState({
    seo_domain_name: "",
    seo_title: "",
    seo_description: "",
    seo_keywords: [""],
  });
  const [seoSettingId, setSeoSettingId] = useState(null);
  const [errors, setErrors] = useState({});

  const validateField = (name, value) => {
    let errorMsg = "";
    if (!value) errorMsg = `${name.replace(/_/g, " ")} is required`;
    setErrors((prev) => ({ ...prev, [name]: errorMsg }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    validateField(name, value);
    setSeoSettings((prev) => ({ ...prev, [name]: value }));
  };

  const handleKeywordChange = (keyword, index) => {
    const newKeywords = [...seoSettings.seo_keywords];
    newKeywords[index] = keyword;
    let keywordErrors = { ...errors };

    // Keyword specific validation logic (example: checking if keyword is empty)
    keywordErrors[`keyword_${index}`] = keyword.trim()
      ? ""
      : "Keyword cannot be empty";

    setSeoSettings((prev) => ({
      ...prev,
      seo_keywords: newKeywords,
    }));
    setErrors(keywordErrors);
  };

  const handleAddKeyword = () => {
    setSeoSettings((prev) => ({
      ...prev,
      seo_keywords: [...prev.seo_keywords, ""],
    }));
  };

  const handleRemoveKeyword = (index) => {
    const newKeywords = [...seoSettings.seo_keywords];
    newKeywords.splice(index, 1);
    setSeoSettings((prev) => ({
      ...prev,
      seo_keywords: newKeywords,
    }));
  };

  const isFormValid = () => {
    const { seo_domain_name, seo_title, seo_description, seo_keywords } =
      seoSettings;
    const areFieldsFilled =
      seo_domain_name &&
      seo_title &&
      seo_description &&
      seo_keywords.length > 0 &&
      seo_keywords.every((keyword) => keyword.trim() !== "");
    const areNoErrors = Object.values(errors).every((error) => error === "");
    return areFieldsFilled && areNoErrors;
  };

  const fetchSeoSettings = async () => {
    try {
      const response = await axios.get(`${REACT_APP_BASE_URL}/Seo-settings`);
      if (response.data && response.data.length > 0) {
        const firstSetting = response.data[0];
        setInitialTrackingData(firstSetting);
        setSeoSettings({
          seo_domain_name: firstSetting.seo_domain_name,
          seo_title: firstSetting.seo_title,
          seo_description: firstSetting.seo_description,
          seo_keywords: firstSetting.seo_keywords,
        });
        setSeoSettingId(firstSetting.seo_setting_id);
      }
    } catch (error) {
      console.error("Error fetching SEO settings:", error);
    }
  };

  useEffect(() => {
    fetchSeoSettings();
  }, []);

  const handleSubmit = async () => {
    const apiUrl = `${REACT_APP_BASE_URL}/Seo-settings`;
    const method = seoSettingId ? "patch" : "post";
    const url = seoSettingId ? `${apiUrl}/${seoSettingId}` : apiUrl;

    try {
      const response = await axios({
        method,
        url,
        data: seoSettings,
      });

      if (response.status === 200 || response.status === 201) {
        setSeoSettingId(response.data.seo_setting_id);
        const excludedTrackingData = [
          "seo_setting_id",
          "jbo_id",
          "site_status",
          "mobile_number",
        ];

        const auditLog = compareJSON(
          initialTrackingData,
          seoSettings,
          excludedTrackingData
        );

        // user tracking data
        const trackingUserData = {
          page: "Page Customization",
          page_details: {
            ...(auditLog === "nochange" ? {} : { changes: auditLog }),
            seo_setting_id: initialTrackingData.seo_setting_id,
            activeAccordion: activeAccordion
          },
          method: "Save",
        };

        // Call the trackUser function from the hook
        trackUser(trackingUserData);
        fetchSeoSettings(); // This assumes your GET endpoint returns the updated data
        Swal.fire({
          title: "Success!",
          text: "SEO settings updated successfully.",
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error("Error updating SEO settings:", error);
      Swal.fire({
        title: "Error!",
        text: "There was an issue updating the SEO settings.",
        icon: "error",
        timer: 3000,
        showConfirmButton: true,
      });
    }
  };

  /**
   * Toggles the state of the Products accordion.
   * If it's currently open, it'll close it. If it's closed, it'll open it.
   */

  const handleAccordionChange = () => {
    if (activeAccordion === "SEOPageAccordian") {
      onToggle && onToggle(null); // close the current accordion
    } else {
      onToggle && onToggle("SEOPageAccordian"); // open the clicked accordion
    }
  };

  const { trackUser, compareJSON } = useUserTracking();

  const [initialTrackingData, setInitialTrackingData] = useState({});

  return (
    <div className="px-2">
      <Accordion
        expanded={activeAccordion === "SEOPageAccordian"}
        onChange={handleAccordionChange}
        // expanded={isExpanded} onChange={handleAccordionChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="store-panel-content"
          id="store-panel-header"
        >
          <Typography
            className="font-medium text-xl"
            style={{ fontFamily: "poppins" }}
          >
            SEO Page
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="p-4">
            <div
              className={`rounded-lg bg-white ${
                activeAccordion === "SEOPageAccordian" ? "" : "hidden"
              }`}
            >
              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="seo_domain_name"
                >
                  Domain Name
                </label>
                <input
                  className="shadow appearance-none border rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200 ease-in-out"
                  id="seo_domain_name"
                  type="text"
                  placeholder="Enter domain name"
                  name="seo_domain_name"
                  value={seoSettings.seo_domain_name}
                  onChange={handleInputChange}
                />
                {errors.seo_domain_name && (
                  <p className="text-red-500 text-md font-poppins mt-2">
                    {errors.seo_domain_name}
                  </p>
                )}
              </div>

              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="seo_title"
                >
                  Title
                </label>
                <input
                  className="shadow appearance-none border rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200 ease-in-out"
                  id="seo_title"
                  type="text"
                  placeholder="Enter page title"
                  name="seo_title"
                  value={seoSettings.seo_title}
                  onChange={handleInputChange}
                />
                {errors.seo_title && (
                  <p className="text-red-500 text-md font-poppins mt-2">
                    {errors.seo_title}
                  </p>
                )}
              </div>

              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="seo_description"
                >
                  Description
                </label>
                <textarea
                  className="shadow appearance-none border rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200 ease-in-out"
                  id="seo_description"
                  placeholder="Enter page description"
                  name="seo_description"
                  value={seoSettings.seo_description}
                  onChange={handleInputChange}
                  rows={4}
                />
                {errors.seo_description && (
                  <p className="text-red-500 text-md font-poppins mt-2">
                    {errors.seo_description}
                  </p>
                )}
              </div>

              <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Keywords:
                </label>
                <div className="flex flex-wrap gap-2 mb-3">
                  {seoSettings.seo_keywords.map((keyword, index) => (
                    <div key={index} className="flex items-center gap-2">
                      <input
                        type="text"
                        placeholder="Keyword"
                        value={keyword}
                        onChange={(e) =>
                          handleKeywordChange(e.target.value, index)
                        }
                        className="shadow appearance-none border rounded-lg py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200 ease-in-out"
                      />

                      <button
                        type="button"
                        onClick={() => handleRemoveKeyword(index)}
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-3 rounded-lg focus:outline-none focus:shadow-outline transition duration-200 ease-in-out"
                      >
                        <MdDelete className="h-5 w-5" />
                      </button>
                      {errors[`keyword_${index}`] && (
                        <p className="text-red-500 text-md font-poppins mt-2">
                          {errors[`keyword_${index}`]}
                        </p>
                      )}
                    </div>
                  ))}
                </div>

                <button
                  type="button"
                  onClick={handleAddKeyword}
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-3 px-6 rounded-lg focus:outline-none focus:shadow-outline flex items-center transition duration-200 ease-in-out"
                >
                  <MdAdd className="h-5 w-5 mr-2" />
                  Add
                </button>
              </div>

              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={handleSubmit}
                  disabled={!isFormValid()}
                  className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-lg focus:outline-none focus:shadow-outline transition duration-200 ease-in-out ${
                    !isFormValid() ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                >
                  {!isFormValid() ? (
                    <span>Clear the errors</span>
                  ) : seoSettingId ? (
                    "Update"
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default SEO;
