import React, { useState, useEffect, useRef } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { BsPlusCircle } from "react-icons/bs";
import SunEditor from "suneditor-react";
import { MdDelete } from "react-icons/md";
import "suneditor/dist/css/suneditor.min.css";
import axios from "axios";
import useAxios from "../../Axios/useAxios";
import useTokenFromURL from "../../Context/useTokenFromURL";
import { useToken } from "../../Context/TokenContext";
import Swal from "sweetalert2";
import { BASE_URL } from "../../config";
import PreviewPage from "./PreviewPage";
import useUserTracking from "../../customHook/userTracking";
// Base URL fetched from environment variables
const REACT_APP_BASE_URL = BASE_URL;

/**
 * CustomPage component: A component to manage and display custom pages.
 *
 * @param {Function} onToggle - Callback function to handle accordion toggling.
 * @param {String} activeAccordion - Currently active accordion's name.
 * @param {Function} IsSavedInputs - Flag to determine if inputs are saved.
 */

const CustomPage = ({ onToggle, activeAccordion, IsSavedInputs }) => {
  // Utilize hooks potentially responsible for user authentication and setting up axios for HTTP requests.
  useTokenFromURL();
  useAxios();

  // State Variables:
  // customPages: Stores the array of custom pages' data.
  // errors: Stores the validation errors related to custom pages.
  const [customPages, setCustomPages] = useState([]);

  const [errors, setErrors] = useState([]);
  // State Flag to determine if data has been submitted.
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const { jboId } = useToken();

  /**
   * Handle title changes.
   * Updates the title of a custom page at a specific index.
   *
   * @param {Number} index - The index of the custom page in the array.
   * @param {Object} event - The event object from the input.
   */
  const handleTitleChange = (index, event) => {
    setCustomPages((prev) => {
      const updatedPages = [...prev];
      updatedPages[index].title = event.target.value;
      return updatedPages;
    });
  };

  /**
   * Handle editor changes.
   * Updates the content of a custom page at a specific index.
   *
   * @param {Number} index - The index of the custom page in the array.
   * @param {String} content - The updated content from the editor.
   */
  const handleEditorChange = (index, content) => {
    setCustomPages((prev) => {
      const updatedPages = [...prev];
      updatedPages[index].content = content;
      return updatedPages;
    });
  };
  /**
   * Fetches a custom page by its ID.
   *
   * @param {String} customPageId - The ID of the custom page to fetch.
   * @returns {Array} - Returns the custom page data.
   */
  const fetchCustomPageById = async (customPageId) => {
    try {
      const response = await axios.get(`${REACT_APP_BASE_URL}/custom-page`, {
        params: {
          custom_page_id: customPageId,
        },
      });
      const submitValidate =
        response.data[0].custom_page_title !== null ? true : false;
      setDataSubmitted(submitValidate);
      setInitialTrackingData(response.data[0]);
      return response.data;
    } catch (error) {
      console.error("Failed to fetch custom page data:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "Custom Page Not found"
      ) {
        setDataSubmitted(false);
      }
      throw error;
    }
  };

  /**
   * Handles accordion toggle actions.
   * Fetches data when an accordion is activated and handles its open/close state.
   *
   * @param {Number} index - The index of the custom page in the array.
   */
  const handleAccordionChange = async (index) => {
    const currentAccordionName = "CustomPageAccordian" + index;
    const selectedCustomPage = customPages[index];

    if (activeAccordion === currentAccordionName) {
      onToggle && onToggle(null); // Close the current active accordion
    } else {
      // Collapse other accordions before opening a new one
      onToggle && onToggle(currentAccordionName);
      if (selectedCustomPage.custom_page_id && !selectedCustomPage.content) {
        try {
          const fetchedData = await fetchCustomPageById(
            selectedCustomPage.custom_page_id
          );
          validateCustomPages();

          setCustomPages((prev) => {
            const updatedPages = [...prev];

            fetchedData.forEach((fetchedPage) => {
              const idx = updatedPages.findIndex(
                (p) => p.custom_page_id === fetchedPage.custom_page_id
              );
              if (idx !== -1) {
                updatedPages[idx] = {
                  ...updatedPages[idx],
                  title: fetchedPage.custom_page_title,
                  content: fetchedPage.custom_page_editor,
                };
              }
            });

            return updatedPages;
          });
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Failed to fetch custom page details!",
          });
        }
      }
    }
  };

  /**
   * Fetches the list of all custom pages.
   * Sets the fetched data to the customPages state.
   */

  const fetchCustomPages = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_URL}/custom-page/pages`
      );
      const data = response.data;

      if (data && Array.isArray(data)) {
        const customPageData = data.map((item) => ({
          custom_page_id: item.custom_page_id,
          title: item.custom_page_title,
          content: item.custom_page_editor,
        }));

        setCustomPages(customPageData);
      }
    } catch (error) {
      console.error("Failed fetching custom page details:", error);
    }
  };
  /**
   * Initial fetching of custom pages when the component is mounted.
   */
  useEffect(() => {
    fetchCustomPages();
  }, []);
  /**
   * Creates a new custom page entry.
   *
   * @returns {Object} - Returns the data of the newly created custom page.
   */
  const createCustomPage = async () => {
    try {
      const response = await axios.post(`${REACT_APP_BASE_URL}/custom-page`);
      fetchCustomPages();
      return response.data;
    } catch (error) {
      console.error("Failed to create custom page:", error);
      throw error;
    }
  };

  /**
   * Adds a new custom page to the list of custom pages.
   */
  const handleAddCustomPage = async () => {
    try {
      onToggle && onToggle(null); // Close the current active accordion
      const newCustomPageData = await createCustomPage();

      // user tracking data
      const trackingUserData = {
        page: "Page Customization",
        page_details: {pageCreation : "New custom page created"},
        method: "Save",
      };

      // Call the trackUser function from the hook
      trackUser(trackingUserData);
      setCustomPages((prev) => [...prev, newCustomPageData]);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to create a new custom page!",
      });
    }
  };
  /**
   * Sends the custom page data for saving or updating to the server.
   *
   * @param {String} customPageId - The ID of the custom page.
   * @param {String} title - The title of the custom page.
   * @param {String} content - The content of the custom page.
   * @returns {Object} - Returns the response from the server.
   */

  const submitCustomPageData = async (customPageId, title, content) => {
    try {
      const response = await axios.patch(
        `${REACT_APP_BASE_URL}/custom-page/${customPageId}`,
        {
          custom_page_title: title,
          custom_page_editor: content,
        }
      );
      if (dataSubmitted) {
        Swal.fire({
          title: "Success!",
          text: "Datas Updated successfully.",
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
      } else {
        Swal.fire({
          title: "Success!",
          text: "Datas saved successfully.",
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
      }
      fetchCustomPageById(customPageId);
      // fetchCustomPages();
      return response.data;
    } catch (error) {
      console.error("Failed to submit custom page data:", error);
      throw error;
    }
  };

  /**
   * Handles the submission action for a custom page.
   * It checks if the required fields are present and then submits the data.
   *
   * @param {Object} customPage - The custom page data to be submitted.
   */
  const handleSubmit = async (customPage) => {
    if (customPage.custom_page_id && customPage.title && customPage.content) {
      try {
        await submitCustomPageData(
          customPage.custom_page_id,
          customPage.title,
          customPage.content
        );
        const excludedTrackingData = [
          "jbo_id",
          "position",
          "is_default_editor",
          "is_custom_editor",
          "custom_editor_web",
          "custom_editor_html",
          "website_custom_page_editor_description",
          "website_custom_page_html_description",
          "site_status",
        ];

        const auditLog = compareJSON(
          initialTrackingData,
          {
            custom_page_id: customPage.custom_page_id,
            custom_page_title: customPage.title,
            custom_page_editor: customPage.content,
          },
          excludedTrackingData
        );

        // user tracking data
        const trackingUserData = {
          page: "Page Customization",
          page_details: {
            ...(auditLog === "nochange" ? {} : { changes: auditLog }),
            custom_page_id: customPage.custom_page_id,
            activeAccordion:activeAccordion
          },
          method: "Save",
        };

        // Call the trackUser function from the hook
        trackUser(trackingUserData);
      } catch (error) {
        console.log("error");
      }
    }
  };
  /**
   * Deletes a custom page after getting user confirmation.
   *
   * @param {Number} index - The index of the custom page in the array.
   */
  const handleDeleteCustomPage = (index) => {
    const customPageIdToDelete = customPages[index].custom_page_id;

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this custom page?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${REACT_APP_BASE_URL}/custom-page/${customPageIdToDelete}`)
          .then(() => {
            // user tracking data
            const trackingUserData = {
              page: "Page Customization",
              page_details: {customPage:` custom page id - ${customPageIdToDelete} is  deleted`},
              method: "Save",
            };

            // Call the trackUser function from the hook
            trackUser(trackingUserData);
            const updatedCustomPages = [...customPages];
            updatedCustomPages.splice(index, 1);
            setCustomPages(updatedCustomPages);

            Swal.fire({
              title: "Deleted!",
              text: "Your custom page has been deleted.",
              icon: "success",
              timer: 3000,
              showConfirmButton: false,
            });
            fetchCustomPages();
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Failed to delete the custom page!",
            });
            console.log(error);
          });
      }
    });
  };
  /**
   * Validates custom pages to ensure required fields (title and content) are provided.
   * Updates the errors state with validation messages.
   *
   * @returns {Boolean} - Returns true if all custom pages are valid, false otherwise.
   */
  const validateCustomPages = () => {
    let validationErrors = customPages.map((customPage) => {
      let errors = {};
      if (!customPage.title) {
        errors.title = "Title is required!";
      }
      if (!customPage.content || customPage.content === "<p><br></p>") {
        errors.content = "Content is required!";
      }
      return errors;
    });

    setErrors(validationErrors);
    return validationErrors.every((error) => Object.keys(error).length === 0);
  };

  /**
   * Runs the validation every time the customPages state changes.
   */

  useEffect(() => {
    validateCustomPages();
  }, [customPages]);

  const { trackUser, compareJSON } = useUserTracking();

  const [initialTrackingData, setInitialTrackingData] = useState({});

  return (
    <div
      className="space-y-4 px-2"
      //  className={Object.keys(errors).length > 0 && activeAccordion !== null  ? "opacity-50 space-y-8" : "opacity-100 space-y-8"}
    >
      {customPages.map((customPage, index) => (
        <Accordion
          key={index + "shahid"}
          expanded={activeAccordion === "CustomPageAccordian" + index}
          onChange={() => handleAccordionChange(index)}
          // key={index}
          //  expanded={isExpanded === index} onChange={handleAccordionChange(index)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-custom-content-${index}`}
            id={`panel-custom-header-${index}`}
          >
            <Typography
              className="font-medium text-xl"
              style={{ fontFamily: "poppins" }}
            >
              {customPage.title !== "" && customPage.title !== null
                ? customPage.title
                : `Custom Page ${index + 1}`}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="w-full space-y-6 ">
              <label className="font-medium font-poppins text-gray-600 block">
                Custom Page Title
              </label>
              <input
                type="text"
                placeholder="Enter Title"
                value={customPage.title}
                className="mt-1  focus:ring-blue-500 focus:border-blue-500 block w-2/3 sm:text-sm border-gray-300 rounded-md"
                onChange={(event) => {
                  handleTitleChange(index, event);
                  IsSavedInputs();
                }}
              />

              <div className="mt-1">
                {errors[index]?.title && (
                  <span className="text-red-700 mt-6 bg-red-50 font-poppins px-2 py-1 rounded shadow-md">
                    {errors[index].title}
                  </span>
                )}
              </div>
              <label className="font-medium font-poppins text-gray-600 block">
                Custom Page Content
              </label>
              {/* {customPage.content &&  <PreviewPage content={customPage.content}/>} */}

              <SunEditor
                setDefaultStyle="font-family: poppins; font-size: 15px;"
                setOptions={{
                  height: 400,
                  attributesWhitelist: {
                    all: "style",
                  },
                  buttonList: [
                    [
                      "font",
                      "fontSize",
                      "bold",
                      "subscript",
                      "superscript",
                      "underline",
                    ], // Added 'font'
                    ["fontColor", "hiliteColor", "outdent", "indent", "link"],
                    ["codeView", "undo", "redo", "align", "list", "table"], // Added 'codeView'
                    ["image", "imageGallery", "video", "fullScreen", "preview"],
                  ],
                  // imageGalleryUrl:"https://etyswjpn79.execute-api.ap-northeast-1.amazonaws.com/suneditor-demo",
                  imageGalleryUrl: `${REACT_APP_BASE_URL}/gallery-uploads/CMS_gallery?jbo_id=${jboId}`,
                  // To define custom fonts:
                  font: [
                    "Arial",
                    "Calibri",
                    "Comic Sans",
                    "Courier",
                    "Poppins",
                    "Times New Roman",
                  ],
                  // You can add other options according to your needs
                }}
                setContents={customPage.content}
                onChange={(contentData) => {
                  if (customPage.content !== contentData) {
                    IsSavedInputs();
                    handleEditorChange(index, contentData);
                  }
                }}
              />
              <div className="mt-1">
                {errors[index]?.content && (
                  <span className="text-red-700 mt-6 bg-red-50 font-poppins px-2 py-1 rounded shadow-md">
                    {errors[index].content}
                  </span>
                )}
              </div>
            </div>
          </AccordionDetails>

          <button
            onClick={() => handleSubmit(customPage)}
            className={`bg-gradient-to-r from-blue-500 to-blue-600 mt-4 hover:from-blue-500 hover:to-blue-700 transition duration-300 ease-in-out text-white font-bold py-2 px-4 rounded-md block mx-auto ${
              errors[index]?.title || errors[index]?.content
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
            disabled={errors[index]?.title || errors[index]?.content}
          >
            {errors[index]?.title || errors[index]?.content
              ? "Fill the required fields"
              : dataSubmitted
              ? "Update"
              : "Save"}
          </button>

          <div className="flex justify-end items-end p-3">
            <button
              onClick={(event) => {
                event.stopPropagation(); // This prevents the accordion from toggling when the delete icon is clicked
                handleDeleteCustomPage(index);
              }}
              className="self-end flex items-center space-x-2 text-red-600 font-poppins p-3 border-red-500 border-2 rounded hover:border-red-300 hover:bg-red-100 transform transition-all duration-300 hover:scale-105 hover:shadow-md"
            >
              <span>Delete</span>
              <MdDelete size={20} />
            </button>
          </div>
        </Accordion>
      ))}

      <button
        onClick={() => {
          handleAddCustomPage();
        }}
        className="flex items-center space-x-2 mt-4 text-pink-600 font-poppins px-2 py-3 border-pink-500 border-2 rounded hover:border-pink-300 hover:bg-pink-100 transform transition-all duration-300 hover:scale-105 hover:shadow-md"
      >
        <span>Custom Page</span>
        <BsPlusCircle size={20} />
      </button>
    </div>
  );
};

export default CustomPage;
