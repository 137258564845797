import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import PreviewCollage from "./PreviewCollage";
import useTokenFromURL from "../../../Context/useTokenFromURL";
import useAxios from "../../../Axios/useAxios";
import Skeleton from "./Skeleton";
import { BASE_URL } from "../../../config";
import SelectedTemplate from "./SelectedTemplate";
import CollageTemplates from "./CollageTemplates";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { CgDetailsMore } from "react-icons/cg";
import useUserTracking from "../../../customHook/userTracking";
const REACT_APP_BASE_URL = BASE_URL;
const CollageCustomization = ({
  refresh,
  widgetType,
  widgetId,
  fetchWidgetOrder,
  setHasInteracted,
  setUnsavedChanges,
  imageCountForSelectedTemplate,
  setImageCountForSelectedTemplate,
}) => {
  const [formValues, setFormValues] = useState({
    widgetNameInput: "",
    widgetTittleInput: "",
    widgetTypeInput: widgetType,
    displayType: "",
  });

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useTokenFromURL();
  useAxios();

  const IsSavedInputs = () => {
    setHasInteracted(true);
    setUnsavedChanges(true);
  };

  const [activeBlock, setActiveBlock] = useState(0);
  const [SelectedActiveBlock, setSelectedActiveBlock] = useState(null);
  // const [collageId, setCollageId] = useState(null);
  const [templatesModelOpen, setTemplatesModelOpen] = useState(false);
  const [, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [DataSubmitted, setDataSubmitted] = useState(false);
  const [blockImages, setBlockImages] = useState(
    Array(1).fill({ image_url: null, image_link: null })
  );
  const [activeBlockImages, setActiveBlockImages] = useState(
    Array(1).fill({ image_url: null, image_link: null })
  );
  const [previewActiveBlock, setPreviewActiveBlock] = useState(0);

  const handleBlockClickForCommonTemplate = (blockIndex) => {
    setActiveBlock(blockIndex);
    blockIndex != previewActiveBlock
      ? setBlockImages(Array(1).fill({ image_url: null, image_link: null }))
      : setBlockImages(activeBlockImages);
  };

  // New function to fetch banner data and set form fields
  const fetchCollageData = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_URL}/collage/${widgetId}`
      );
      setLoading(true);
      setInitialTrackingData(response.data[0]);
      setFormFields(response.data[0]);
    } catch (error) {
      setLoading(true);
      console.error(error);
    }
  };

  const setFormFields = (data) => {
    const displayType = data.is_full_width === 1 ? "fullWidth" : "boxWidth";

    setFormValues({
      widgetNameInput: data.widget_name,
      widgetTittleInput: data.widget_title,
      widgetTypeInput: data.widget_type,
      displayType: displayType,
    });

    setActiveBlock(data.collage_structure);
    setPreviewActiveBlock(data.collage_structure);
    setSelectedActiveBlock(data.collage_structure);
    // setCollageId(data.collage_id);

    const isImagesPresent =
      data.collage_image && data.collage_image.length !== 0;
    setDataSubmitted(isImagesPresent);

    setBlockImages(data.collage_image);
    setActiveBlockImages(data.collage_image);
  };

  useEffect(() => {
    fetchCollageData();
  }, [refresh]);

  const handleChange = (e) => {
    IsSavedInputs();
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    // setHasInteracted(true);
  };
  const validateImages = (images) => {
    if (imageCountForSelectedTemplate == 0 && images.length == 0)
      return { isValid: false, error: `Select a template and upload images` };
    if (
      imageCountForSelectedTemplate != images.length &&
      imageCountForSelectedTemplate != 0
    )
      return { isValid: false, error: `Upload all images` };
    for (let i = 0; i < images.length; i++) {
      const image = images[i];

      if (!image || !image.image_url) {
        return { isValid: false, error: `Upload Image` };
      }

      //builde error fix
      // if (!image.image_link || image.image_link === "") {
      //   return { isValid: false, error: `Image link for Image ${i + 1} is required` };
      // }

      // if (!image.image_link.startsWith("https")) {
      //   return { isValid: false, error: `Image link for Image ${i + 1} must have "https" format` };
      // }
    }
    return { isValid: true };
  };

  const getActiveImages = () => {
    return blockImages;
  };

  const isFormValids = () => {
    const filteredFormValues = Object.entries(formValues)
      .filter(([key]) => key !== "widgetTittleInput")
      .map(([value]) => value);

    const hasFormValues = filteredFormValues.every(
      (value) => value.trim() !== ""
    );
    const activeImages = getActiveImages();

    if (!hasFormValues) {
      return {
        isValid: false,
        error: "Please fill out the required form values.(*)",
      };
    }

    const imageValidation = validateImages(activeImages);
    if (!imageValidation.isValid) {
      return imageValidation;
    }

    return { isValid: true };
  };

  const validation = isFormValids();

  useEffect(() => {
    isFormValids();
  }, [blockImages]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    try {
      let imageUrl;

      imageUrl = blockImages
        .map((imgObj) => ({
          image_url: imgObj.image_url,
          image_link: imgObj.image_link,
        }))
        .filter((image) => image.image_url !== null);

      // Construct the payload
      const payload = {
        collage_image: imageUrl,
        collage_structure: activeBlock,
        widget_name: formValues.widgetNameInput,
        widget_title: formValues.widgetTittleInput,
        is_full_width: formValues.displayType === "fullWidth" ? 1 : 0,
        is_box_width: formValues.displayType === "boxWidth" ? 1 : 0,
      };

      // Make the API call
      axios
        .patch(`${REACT_APP_BASE_URL}/collage/${widgetId}`, payload)
        .then(() => {
          const excludedTrackingData = [
            "collage_id",
            "widget_row_number",
            "site_status",
            "widget_type",
            "widget_order_id",
            "jbo_id",
          ];

          const auditLog = compareJSON(
            initialTrackingData,
            payload,
            excludedTrackingData
          );

          // user tracking data
          const trackingUserData = {
            page: "HomePage Customization",
            page_details: {
              ...(auditLog === "nochange" ? {} : { changes: auditLog }),
              collage_id: initialTrackingData.collage_id,
              widget_row_number: initialTrackingData.widget_row_number,
              widget_type: initialTrackingData.widget_type,
              widget_order_id: initialTrackingData.widget_order_id,
            },
            method: "Save",
          };

          // Call the trackUser function from the hook
          trackUser(trackingUserData);

          fetchWidgetOrder();
          fetchCollageData();
        });

      Swal.fire({
        icon: "success",
        title: "Data sent successfully.",
        showConfirmButton: false,
        timer: 1500,
      });

      setUnsavedChanges(false);
      // Handle successful submission as needes
    } catch {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    } finally {
      setIsFormSubmitted(false); // Set isFormSubmitted back to false when form submission is complete
    }
  };

  // useEffect(() => {
  //   if (collageId !== null) {
  //     setBlockImages(Array(5).fill(null));
  //   }
  // }, [collageId]);

  // trackUser Analytics
  const { trackUser, compareJSON } = useUserTracking();

  const [initialTrackingData, setInitialTrackingData] = useState({});

  return (
    <>
      {!loading ? (
        <Skeleton />
      ) : (
        <div className="h-full w-full relative">
          <div className="h-[94%] overflow-auto pb-5">
            <h1 className="text-lg font-bold font-poppins text-gray-700 text-center pb-2">
              Collage Customization
            </h1>
            <form className="space-y-4 pt-3 px-4">
              <div className="flex space-x-4">
                <div className="w-1/2">
                  <label
                    htmlFor="widgetNameInput"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Widget Name*
                  </label>
                  <input
                    id="widgetNameInput"
                    name="widgetNameInput"
                    type="text"
                    placeholder={formValues.widgetNameInput}
                    value={formValues.widgetNameInput}
                    onChange={handleChange}
                    className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div className="w-1/2 ">
                  <label
                    htmlFor="widgetTittleInput"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Widget Title
                  </label>
                  <input
                    id="widgetTittleInput"
                    name="widgetTittleInput"
                    type="text"
                    placeholder={formValues.widgetTittleInput}
                    value={formValues.widgetTittleInput}
                    onChange={handleChange}
                    className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div className="w-1/2 ">
                  <label
                    htmlFor="widgetTypeInput"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Widget Type
                  </label>
                  <input
                    id="widgetTypeInput"
                    name="widgetTypeInput"
                    type="text"
                    disabled
                    placeholder={formValues.widgetTypeInput}
                    value={formValues.widgetTypeInput}
                    className="mt-1 focus:ring-blue-500 bg-gray-200 opacity-50 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div className="mt-4 twelfth-step">
                <span className="block text-sm font-medium text-gray-700">
                  Display Type
                </span>
                <div className="flex mt-2 gap-3">
                  <label className="flex items-center w-1/2 px-3 py-2 bg-gray-100 rounded-lg cursor-pointer">
                    <input
                      type="radio"
                      name="displayType"
                      value="fullWidth"
                      checked={formValues.displayType === "fullWidth"}
                      onChange={handleRadioChange}
                      className="form-radio h-5 w-5 text-blue-600"
                    />
                    <span className="ml-2">Full Width</span>
                  </label>
                  <label className="flex items-center w-1/2 px-3 py-2 bg-gray-100 rounded-lg cursor-pointer">
                    <input
                      type="radio"
                      name="displayType"
                      value="boxWidth"
                      checked={formValues.displayType === "boxWidth"}
                      onChange={handleRadioChange}
                      className="form-radio h-5 w-5 text-blue-600"
                    />
                    <span className="ml-2">Box Width</span>
                  </label>
                </div>
              </div>

              <div className="thirteenth-step">
                <PreviewCollage
                  blockImages={activeBlockImages}
                  SelectedActiveBlock={SelectedActiveBlock}
                  setBlockImages={setBlockImages}
                  IsSavedInputs={IsSavedInputs}
                />
                <CollageTemplates
                  activeBlock={activeBlock}
                  setTemplatesModelOpen={setTemplatesModelOpen}
                  templatesModelOpen={templatesModelOpen}
                  handleBlockClickForCommonTemplate={
                    handleBlockClickForCommonTemplate
                  }
                  setImageCountForSelectedTemplate={
                    setImageCountForSelectedTemplate
                  }
                />
              </div>

              <div className="flex row justify-center items-center mt-3 space-x-2">
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="contained"
                    startIcon={<CgDetailsMore />}
                    onClick={() => {
                      setTemplatesModelOpen(true);
                    }}
                  >
                    See More
                  </Button>
                </Stack>
              </div>
              <SelectedTemplate
                SelectedActiveBlock={SelectedActiveBlock}
                activeBlock={activeBlock}
                IsSavedInputs={IsSavedInputs}
                blockImages={blockImages}
                setBlockImages={setBlockImages}
              />
              <div>
                {!validation.isValid && (
                  <span className="font-poppins text-red-500">
                    {validation.error}
                  </span>
                )}
              </div>
            </form>
          </div>
          <div className="w-full absolute left-0 bottom-0 px-2 pt-2 bg-white">
            <button
              onClick={handleFormSubmit}
              disabled={!validation.isValid}
              className={
                !validation.isValid
                  ? "text-white font-bold py-2 px-4 rounded-md opacity-50 bg-blue-500"
                  : "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md"
              }
            >
              {DataSubmitted ? "Update" : "Save"}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CollageCustomization;
