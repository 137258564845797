import React, { useState, useEffect } from "react";
import Skeleton from "../Information Slider/Skeleton";
import Preview from "./Preview";
import useAxios from "../../../Axios/useAxios";
import useTokenFromURL from "../../../Context/useTokenFromURL";
import axios from "axios";
import Swal from "sweetalert2";
import Accordion from "@mui/material/Accordion";
import Gallery from "../../Gallery";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FiUpload } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import Modal from "react-modal";
import { AiOutlinePlus, AiOutlineClose } from "react-icons/ai";
import { BASE_URL } from "../../../config";
import useUserTracking from "../../../customHook/userTracking";
const REACT_APP_BASE_URL = BASE_URL;
const CategorySlider = ({
  widgetType,
  unsavedChanges,
  fetchWidgetOrder,
  setUnsavedChanges,
  setHasInteracted,
  hasInteracted,
  refresh,
  widgetId,
}) => {
  useTokenFromURL();
  useAxios();
  const [formValues, setFormValues] = useState({
    widget_name: "",
    widgetTittleInput: "",
    widgetTypeInput: "",
  });
  const [CategorySliderId, setCategorySliderId] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentMenuId, setCurrentMenuId] = useState(null);
  const [DataSubmitted, setDataSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [categoryVisuals, setCategoryVisuals] = useState({
    sliderHeader: "",
    numProducts: 2,
    rotationSeconds: 2,
  });
  const [menus, setMenus] = useState([]);
  const validateForm = () => {
    const newErrors = {};

    // Validate slider header
    if (!formValues.widget_name.trim()) {
      newErrors.widget_name = "Widget Name is Required.";
    }
    if (menus.length < 5) {
      newErrors.menuRequired = "Menu must be at least 5.";
    }
    // Validate menus
    menus.forEach((menu) => {
      if (!menu.name.trim()) {
        newErrors[`name_${menu.id}`] = "Name is Required.";
      }
      if (!menu.type) {
        newErrors[`type_${menu.id}`] = "Image Type is Required.";
      }
      if (menu.type === "customImage" && !menu.image) {
        newErrors[`image_${menu.id}`] = "Image is Required.";
      }

      if (menu.type === "sku" && (!menu.sku || !menu.sku.trim())) {
        newErrors[`sku_${menu.id}`] = "Sku is Required.";
      }

      if (!menu.link.trim()) {
        newErrors[`link_${menu.id}`] = "Menu Link is Required.";
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };
  const validateSku = async (menuId, value) => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_URL}/product-master/${value}`
      );
      if (response.data.statusCode && response.data.statusCode === 404) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [`sku_${menuId}`]: response.data.message || "Invalid sku.",
        }));
      } else {
        // If the SKU is valid, clear any existing SKU error for this menuId
        setErrors((prevErrors) => {
          const updatedErrors = { ...prevErrors };
          delete updatedErrors[`sku_${menuId}`];
          return updatedErrors;
        });
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data.statusCode &&
        error.response.data.statusCode === 404
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [`sku_${menuId}`]: error.response.data.message || "Invalid sku.",
        }));
      } else {
        // Handle other unexpected errors here or set a generic error message if you like
      }
    }
  };

  useEffect(() => {
    validateForm(); // Run validation when component updates
  }, [categoryVisuals, menus, formValues]);

  const preventDirectInput = (event) => {
    // Prevent direct typing
    event.preventDefault();
  };

  const IsSavedInputs = () => {
    setHasInteracted(true);
    setUnsavedChanges(true);
  };
  const handleChange = (e) => {
    // setIsFormSubmitted(true);
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    IsSavedInputs();
  };
  const handleVisualChange = (field, value) => {
    const updatedVisuals = { ...categoryVisuals };
    updatedVisuals[field] = value;
    setCategoryVisuals(updatedVisuals);
    IsSavedInputs();
  };
  const handleMenuChange = (menuId, field, value) => {
    setMenus((prevMenus) => {
      return prevMenus.map((menu) => {
        if (menu.id === menuId) {
          if (field === "type") {
            if (value === "customImage") {
              return { ...menu, [field]: value, sku: null };
            }
            if (value === "sku") {
              return { ...menu, [field]: value, image: null };
            }
          } else {
            return { ...menu, [field]: value };
          }
        }
        return menu;
      });
    });
    IsSavedInputs();
  };

  const customModalStyles = {
    content: {
      width: "80%", // Adjust the width as per your requirement
      margin: "auto", // Center the modal horizontally
    },
  };
  const fetchCategoryData = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_URL}/category-slider/${widgetId}`
      );
      setLoading(true);
      setFormFields(response.data[0]);
      setInitialTrackingData(response.data[0]);
    } catch (error) {
      setLoading(false);
      console.error(true);
    }
  };
  useEffect(() => {
    fetchCategoryData();
  }, [refresh]);

  const setFormFields = (data) => {
    setFormValues({
      widget_name: data.widget_name,
      widgetTypeInput: data.widget_type,
      widgetTittleInput: data.widget_title,
    });
    setCategoryVisuals({
      sliderHeader: data.slider_header,
      numProducts: data.number_of_product,
      rotationSeconds: data.rotation_seconds,
    });
    setCategorySliderId(data.category_slider_id);
    // set menus state
    setMenus(
      data.menu_deatil.map((menu, index) => ({
        id: index + 1, // Assign index starting from 1
        name: menu.name,
        type: menu.is_image === 1 ? "customImage" : "sku",
        image: menu.image_url,
        sku: menu.sku,
        link: menu.menu_link,
      }))
    );
    const formsubmit = data.slider_header !== "" ? true : false;
    setDataSubmitted(formsubmit);
  };

  const openModal = (menuId) => {
    setCurrentMenuId(menuId); // Set the current menu ID before opening the modal
    setIsModalOpen(true);
  };

  const handleImageSelect = (selectedImage) => {
    if (currentMenuId !== null) {
      setMenus((prevMenus) => {
        return prevMenus.map((menu) => {
          if (menu.id === currentMenuId) {
            return { ...menu, image: selectedImage };
          }
          return menu;
        });
      });
      closeModal(); // Close the modal and reset the current menu ID
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentMenuId(null); // Reset the current menu ID when the modal is closed
  };

  const addMenu = () => {
    const newId = menus.length ? menus[menus.length - 1].id + 1 : 1;
    const newMenu = { id: newId, name: "", link: "", image: null, type: "" };
    setMenus([...menus, newMenu]);
    IsSavedInputs();
  };

  const deleteMenu = (id) => {
    const updatedMenus = menus.filter((menu) => menu.id !== id);
    setMenus(updatedMenus);
    IsSavedInputs();
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true);

      const payload = {
        widget_name: formValues.widget_name,
        widget_type: formValues.widgetTypeInput,
        widget_title: formValues.widgetTittleInput,
        slider_header: categoryVisuals.sliderHeader,
        number_of_product: Number(categoryVisuals.numProducts),
        rotation_seconds: Number(categoryVisuals.rotationSeconds),
        menu_deatil: menus.map((menu) => {
          let detail = {
            name: menu.name,
            menu_link: menu.link,
          };

          if (menu.type === "customImage") {
            detail.is_image = 1;
            detail.is_sku = 0;
            detail.image_url = menu.image;
          } else if (menu.type === "sku") {
            detail.is_image = 0;
            detail.is_sku = 1;
            detail.sku = menu.sku;
          }

          return detail;
        }),
      };

      try {
        await axios
          .patch(`${REACT_APP_BASE_URL}/category-slider/${widgetId}`, payload)
          .then(() => {
            const excludedTrackingData = [
              "widget_row_number",
              "category_slider_id",
              "site_status",
              "widget_type",
              "widget_order_id",
              "jbo_id",
            ];

            const auditLog = compareJSON(
              initialTrackingData,
              payload,
              excludedTrackingData
            );

            // user tracking data
            const trackingUserData = {
              page: "HomePage Customization",
              page_details: {
                ...(auditLog === "nochange" ? {} : { changes: auditLog }),
                category_slider_id: initialTrackingData.category_slider_id,
                widget_row_number: initialTrackingData.widget_row_number,
                widget_type: initialTrackingData.widget_type,
                widget_order_id: initialTrackingData.widget_order_id,
              },
              method: "Save",
            };

            // Call the trackUser function from the hook
            trackUser(trackingUserData);

            fetchWidgetOrder();
            fetchCategoryData();
            if (DataSubmitted) {
              Swal.fire({
                icon: "success",
                title: "Data Updated successfully.",
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "Data Sent successfully.",
                showConfirmButton: false,
                timer: 1500,
              });
            }
          });
        setShowPreview(true);
        setUnsavedChanges(false);
        setLoading(false);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.error("Error updating category slider:", error);
        setLoading(false);
      } finally {
        setShowPreview(false);
        // setIsFormSubmitted(false); // Set isFormSubmitted back to false when form submission is complete
      }
    }
  };

  // trackUser Analytics
  const { trackUser, compareJSON } = useUserTracking();

  const [initialTrackingData, setInitialTrackingData] = useState({});

  return (
    <>
      {!loading ? (
        <Skeleton />
      ) : (
        <div className="h-full w-full relative">
          <div className="h-[94%] overflow-auto px-4 pb-5">
            <h1 className="text-lg text-center font-bold pb-2 font-poppins text-gray-700">
              Category Slider Customization
            </h1>
            {DataSubmitted ? (
              <Preview
                widgetId={widgetId}
                CategorySliderId={CategorySliderId}
                showPreview={showPreview}
              />
            ) : (
              false
            )}
            <div className="nineteenth-step">
              <form className="space-y-4 pt-6">
                <div className="flex space-x-4">
                  <div className="w-1/2  ">
                    <label
                      htmlFor="widget_name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Widget Name*
                    </label>
                    <input
                      id="widget_name"
                      name="widget_name"
                      type="text"
                      placeholder={formValues.widget_name}
                      value={formValues.widget_name}
                      onChange={handleChange}
                      className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                    {errors.widget_name && (
                      <p className="text-red-500 text-sm font-poppins">
                        {errors.widget_name}
                      </p>
                    )}
                  </div>

                  <div className="w-1/2 ">
                    <label
                      htmlFor="widgetTittleInput"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Widget Title
                    </label>
                    <input
                      id="widgetTittleInput"
                      name="widgetTittleInput"
                      type="text"
                      placeholder={formValues.widgetTittleInput}
                      value={formValues.widgetTittleInput}
                      onChange={handleChange}
                      className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="w-1/2">
                    <label
                      htmlFor="widgetTypeInput"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Widget Type
                    </label>
                    <input
                      id="widgetTypeInput"
                      name="widgetTypeInput"
                      type="text"
                      disabled
                      placeholder={formValues.widgetTypeInput}
                      value={formValues.widgetTypeInput}
                      className="mt-1 focus:ring-blue-500 bg-gray-200 opacity-50 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </form>
              <div className="mt-4">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Category Slider Visual</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* <div className="flex space-x-4">
                  <div className="w-2/3">
                    <label
                      htmlFor="widgetNameInput"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Slider Header
                    </label>
                    <input
                      id="sliderHeader"
                      name="sliderHeader"
                      type="text"
                      placeholder={categoryVisuals.sliderHeader}
                      value={categoryVisuals.sliderHeader}
                      onChange={(e) =>
                        handleVisualChange("sliderHeader", e.target.value)
                      }
                      className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                {errors.sliderHeader && (
                  <p className="text-red-500 text-sm font-poppins">
                    {errors.sliderHeader}
                  </p>
                )} */}
                    <div className="flex space-x-4 mt-4">
                      <div className="w-2/3">
                        <label
                          htmlFor="widgetNameInput"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Number of Products at a time (Max: 5)
                        </label>
                        <input
                          id="rotationSeconds"
                          name="rotationSeconds"
                          type="number"
                          min={4}
                          max={5}
                          onKeyDown={preventDirectInput}
                          value={categoryVisuals.numProducts}
                          onChange={(e) =>
                            handleVisualChange("numProducts", e.target.value)
                          }
                          className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                    <div className="flex space-x-4 mt-8">
                      <div className="w-2/3">
                        <label
                          htmlFor="widgetNameInput"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Rotation Seconds
                        </label>
                        <input
                          id="rotationSeconds"
                          name="rotationSeconds"
                          type="number"
                          min={1}
                          max={12}
                          onKeyDown={preventDirectInput}
                          value={categoryVisuals.rotationSeconds}
                          onChange={(e) =>
                            handleVisualChange(
                              "rotationSeconds",
                              e.target.value
                            )
                          }
                          className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>

              <div className="mt-4">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Menu Details</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {menus.map(
                      (menu, index) =>
                        menu.id && (
                          <Accordion key={menu.id}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="nested-panel-content"
                              id="nested-panel-header"
                            >
                              {`Menu ${index + 1}`}
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className="flex space-x-4 ">
                                <div className="w-[70%]  ">
                                  <label
                                    htmlFor="menu_name"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Name
                                  </label>
                                  <input
                                    id="menu_name"
                                    name="menu_name"
                                    type="text"
                                    placeholder="Enter menu name"
                                    value={menu.name}
                                    onChange={(e) =>
                                      handleMenuChange(
                                        menu.id,
                                        "name",
                                        e.target.value
                                      )
                                    }
                                    className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                  />
                                </div>
                              </div>
                              {errors[`name_${menu.id}`] && (
                                <p className="text-red-500 text-sm font-poppins">
                                  {errors[`name_${menu.id}`]}
                                </p>
                              )}

                              {/* Radio Buttons */}
                              <div
                                className={`flex space-x-4 mt-4 items-center ${
                                  errors[`type_${menu.id}`] ? "" : "mb-4"
                                }`}
                              >
                                <label
                                  htmlFor="image_type"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Image Type
                                </label>
                                <input
                                  type="radio"
                                  name={`type_${menu.id}`}
                                  value="customImage"
                                  checked={menu.type === "customImage"}
                                  onChange={() =>
                                    handleMenuChange(
                                      menu.id,
                                      "type",
                                      "customImage"
                                    )
                                  }
                                />
                                <label className="text-gray-700 mr-4">
                                  Custom Image
                                </label>
                                <input
                                  type="radio"
                                  name={`type_${menu.id}`}
                                  value="sku"
                                  checked={menu.type === "sku"}
                                  onChange={() =>
                                    handleMenuChange(menu.id, "type", "sku")
                                  }
                                />
                                <label className="text-gray-700">Sku</label>
                              </div>

                              {errors[`type_${menu.id}`] && (
                                <p className="text-red-500 text-sm font-poppins mb-4">
                                  {errors[`type_${menu.id}`]}
                                </p>
                              )}

                              {/* Conditional Rendering */}
                              {menu.type === "customImage" ? (
                                <div>
                                  <Modal
                                    isOpen={isModalOpen}
                                    onRequestClose={() => setIsModalOpen(false)}
                                    className="modal"
                                    style={customModalStyles}
                                    overlayClassName="overlay"
                                  >
                                    <Gallery
                                      dimension="1:1"
                                      onImageSelect={(selectedImage) =>
                                        handleImageSelect(selectedImage)
                                      }
                                      closeModal={closeModal}
                                    />
                                  </Modal>
                                  {/* Your modal starts here */}

                                  {/* Rest of your component */}

                                  <div className="my-2 bg-gray-100 p-4 rounded-md">
                                    <div className="flex items-center justify-center space-x-4">
                                      <div className="border-2 border-blue-500 rounded-md flex items-center justify-center p-2 cursor-pointer h-36 w-48">
                                        {menu.image ? (
                                          <img
                                            src={menu.image}
                                            alt="data"
                                            className="w-full h-full object-cover rounded-md"
                                          />
                                        ) : (
                                          <button
                                            onClick={() => openModal(menu.id)}
                                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md"
                                          >
                                            <FiUpload size="1.5em" />
                                          </button>
                                        )}
                                      </div>
                                      {menu.image && (
                                        <div
                                          className="text-red-500 cursor-pointer"
                                          onClick={() => {
                                            setMenus((prevMenus) => {
                                              return prevMenus.map(
                                                (menuItem) => {
                                                  if (menuItem.id === menu.id) {
                                                    return {
                                                      ...menuItem,
                                                      image: null,
                                                    };
                                                  }
                                                  return menuItem;
                                                }
                                              );
                                            });
                                          }}
                                        >
                                          <MdDelete size="1.5em" />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  {errors[`image_${menu.id}`] && (
                                    <p className="text-red-500 text-sm font-poppins">
                                      {errors[`image_${menu.id}`]}
                                    </p>
                                  )}
                                </div>
                              ) : menu.type === "sku" ? (
                                <>
                                  <div className="mb-2">
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                      Sku
                                    </label>
                                    <input
                                      type="text"
                                      value={menu.sku || ""}
                                      onChange={(e) => {
                                        handleMenuChange(
                                          menu.id,
                                          "sku",
                                          e.target.value
                                        );
                                        if (!e.target.value.trim()) {
                                          setErrors((prevErrors) => ({
                                            ...prevErrors,
                                            [`sku_${menu.id}`]:
                                              "Sku is required.",
                                          }));
                                        } else {
                                          validateSku(menu.id, e.target.value);
                                        }
                                      }}
                                      className="focus:ring-blue-500 focus:border-blue-500 block w-[70%] sm:text-sm border-gray-300 rounded-md"
                                      placeholder="Enter Sku"
                                    />

                                    {errors[`sku_${menu.id}`] && (
                                      <p className="text-red-500 text-sm font-poppins">
                                        {errors[`sku_${menu.id}`]}
                                      </p>
                                    )}
                                  </div>
                                </>
                              ) : null}
                              <div className="w-[70%]  ">
                                <label
                                  htmlFor="menu_name"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Menu Link
                                </label>
                                <input
                                  id="menu_link"
                                  name="menu_link"
                                  type="text"
                                  placeholder="Enter menu link"
                                  value={menu.link}
                                  onChange={(e) =>
                                    handleMenuChange(
                                      menu.id,
                                      "link",
                                      e.target.value
                                    )
                                  }
                                  className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>
                              {errors[`link_${menu.id}`] && (
                                <p className="text-red-500 text-sm font-poppins">
                                  {errors[`link_${menu.id}`]}
                                </p>
                              )}

                              <div className="flex justify-end mt-4">
                                <button
                                  onClick={() => deleteMenu(menu.id)}
                                  className="px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-lg shadow-md"
                                >
                                  Delete
                                </button>
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        )
                    )}
                    <button
                      className="flex items-center space-x-2 text-blue-600 hover:text-blue-600 mt-4"
                      onClick={addMenu}
                    >
                      <AiOutlinePlus size="1em" />
                      <span> Add Menu Box</span>
                    </button>
                    {errors.menuRequired && (
                      <p className="text-red-500 text-sm mt-2">
                        {errors.menuRequired}
                      </p>
                    )}
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>

            {loading ? (
              <div className="pt-3">
                {Object.keys(errors).length > 0 ? (
                  <span className="text-red-500 font-poppins text-md ">
                    ** Kindly fill the all required fields
                  </span>
                ) : (
                  false
                )}
              </div>
            ) : (
              false
            )}
          </div>
          <div className="w-full absolute left-0 bottom-0 px-2 pt-2 bg-white">
            <button
              onClick={handleFormSubmit}
              disabled={Object.keys(errors).length > 0}
              // disabled={Object.keys(formErrors).length !== 0 || Object.keys(skuError).length !== 0 || isSkuRequired()}
              className={
                Object.keys(errors).length > 0
                  ? "text-white font-bold py-2 px-4 rounded-md opacity-50 bg-blue-500 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md"
              }
            >
              {DataSubmitted ? "Update" : "Save"}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CategorySlider;
