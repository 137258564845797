import { useState } from "react";
import { CgClose } from "react-icons/cg";
import { FiLink, FiUpload } from "react-icons/fi";
import Gallery from "../../../Gallery";
import Modal from "react-modal";
import { MdFileUpload } from "react-icons/md";

export const RenderCommonUpload = ({
  buttonIndex,
  IsSavedInputs,
  blockImages,
  setBlockImages,
  dimension = "1:1",
  preview,
}) => {
  const [currentEditingImageIndex, setCurrentEditingImageIndex] =
    useState(null);

  const [menuLinkInput, setMenuLinkInput] = useState("");

  const [showModal, setShowModal] = useState(false);

  const [, setReqDimension] = useState("");

  const [selectedButton, setSelectedButton] = useState(null); // Index of the button clicked

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const customModalStyles = {
    content: {
      width: "80%", // Adjust the width as per your requirement
      margin: "auto", // Center the modal horizontally
    },
  };

  const handleDeleteClick = (
    index,
    IsSavedInput = IsSavedInputs,
    setBlockImage = setBlockImages
  ) => {
    IsSavedInput();
    const newImages = [...blockImages];
    newImages[index] = { image_url: null, image_link: null };
    setBlockImage(newImages);
  };

  const handleLinkClick = (index) => {
    setCurrentEditingImageIndex(index); // set the current image being edited
    setMenuLinkInput(blockImages[index]?.image_link || "");
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleImageSelect = (image, setBlockImage = setBlockImages) => {
    IsSavedInputs();
    setBlockImage((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages[selectedButton] = { image_url: image, image_link: "" };
      return updatedImages;
    });
    setModalIsOpen(false);
  };

  const handleModalSubmit = (
    e,
    setBlockImage = setBlockImages,
    blockImage = blockImages
  ) => {
    e.preventDefault();
    const newImages = [...blockImage];

    if (currentEditingImageIndex !== null) {
      const currentImage = newImages[currentEditingImageIndex];

      if (typeof currentImage === "string") {
        newImages[currentEditingImageIndex] = {
          image_url: currentImage,
          image_link: menuLinkInput,
        };
      } else {
        currentImage.image_link = menuLinkInput;
      }
    }

    setBlockImage(newImages);
    closeModal();
  };

  const handleUploadClick = (buttonIndex) => {
    if (buttonIndex === 0) {
      setReqDimension("3.7:3");
    } else {
      setReqDimension("6.4:2.5");
    }
    setSelectedButton(buttonIndex);
    setModalIsOpen(true);
  };

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    >
      {blockImages[buttonIndex]?.image_url && (
        <>
          <img
            src={
              blockImages[buttonIndex]?.image_url || blockImages[buttonIndex]
            }
            alt="Selected"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleLinkClick(buttonIndex);
            }}
            style={{
              position: "absolute",
              top: "3rem",
              right: "0",
              backgroundColor: blockImages[buttonIndex]?.image_link
                ? "green"
                : "blue",
              borderRadius: "50%",
              cursor: "pointer",
              border: "none",
              padding: "6px",
              transform: "translateY(-50%)",
              visibility: `${preview ? "hidden" : ""}`,
            }}
          >
            <FiLink size="1rem" color="white" />
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleDeleteClick(buttonIndex);
            }}
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              backgroundColor: "red",
              borderRadius: "50%",
              cursor: "pointer",
              border: "none",
              padding: "6px",
              visibility: `${preview ? "hidden" : ""}`,
            }}
          >
            <CgClose size="1rem" color="white" />
          </button>
          <div
            onClick={(e) => {
              e.preventDefault();
              handleUploadClick(buttonIndex);
            }}
            style={{
              position: "absolute",
              top: "4.3rem",
              right: "0",
              backgroundColor: "blue",
              borderRadius: "50%",
              cursor: "pointer",
              border: "none",
              padding: "6px",
              visibility: `${preview ? "hidden" : ""}`,
            }}
          >
            <MdFileUpload size="1rem" color="white" />
          </div>
        </>
      )}
      {!blockImages[buttonIndex]?.image_url && (
        <button
          onClick={(e) => {
            e.preventDefault();
            handleUploadClick(buttonIndex);
          }}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            visibility: `${preview ? "hidden" : ""}`,
          }}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md"
        >
          <FiUpload size="1.5em" />
        </button>
      )}
      {showModal && currentEditingImageIndex !== null && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="relative bg-white rounded-lg shadow-lg p-6 w-96">
            <button
              onClick={closeModal}
              className="absolute top-2 right-2 text-red-500 hover:text-red-700"
            >
              <CgClose size="1.5em" />
            </button>
            <div className="flex flex-col items-start space-y-4 font-poppins">
              <label className="flex flex-col w-full">
                <span className="mb-2 text-lg">
                  {" "}
                  Menu link URL for image {currentEditingImageIndex + 1}:
                </span>
                <input
                  type="text"
                  name={`linkURL_${currentEditingImageIndex}`}
                  value={menuLinkInput}
                  placeholder="https://jpencil.com/gold-jewellery/womens/necklace"
                  className="p-2 border rounded-md focus:ring-1 focus:ring-blue-500 focus:outline-none"
                  onChange={(e) => setMenuLinkInput(e.target.value)}
                />
              </label>
              <button
                onClick={handleModalSubmit}
                className="mt-4  bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className="modal"
        style={customModalStyles}
        overlayClassName="overlay"
      >
        <Gallery
          onImageSelect={handleImageSelect}
          dimension={dimension}
          closeModal={() => setModalIsOpen(false)}
        />
      </Modal>
    </div>
  );
};
